.calendar-body {
    padding: 20px;
}

.calendar-heading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999; /* Ensures it stays above other content */
}


.calendar-heading h1 {
    color: #575252;
    font-size: 20px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-container {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Adjust as needed */
    right: 0;
    width: 250px; 
    background-color: #ffffff;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.user-info {
    border: px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.user-info:hover{
    background-color: #f5f2f2;
}
.user-info-content {
    display: flex;
    align-items: center;
}

.profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    margin: 0;
    font-weight: bold;
}

.user-subtitle {
    margin: 0;
    color: #888888; /* Subtitle color */
}

.logout {
    display: flex;
    align-items: center;
}

.logout:hover{
    background-color: #edecec;
}
.logout svg {
    font-size: 15px;
    margin-right: 5px;
}

.logout p {
    margin: 0;
}

.separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
}


.calendar-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  

