.profile_pages {
  padding: 20px;
}

.profile-headings {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 17px;
  margin-bottom: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 999;
}

.profile-headings h1 {
  color: #575252;
  font-size: 20px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-container {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 250px;
  background-color: #ffffff;
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.user-info {
  border: px solid #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-info:hover {
  background-color: #f5f2f2;
}
.user-info-content {
  display: flex;
  align-items: center;
}

.profile-image-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  margin: 0;
  font-weight: bold;
}

.user-subtitle {
  margin: 0;
  color: #888888;
}

.logout {
  display: flex;
  align-items: center;
}

.logout:hover {
  background-color: #edecec;
}
.logout svg {
  font-size: 15px;
  margin-right: 5px;
}

.logout p {
  margin: 0;
}

.separator {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #bdbdbd;
}

@media screen and (max-width: 768px) {
  .profile-headings {
    width: calc(90% - 40px);
  }
  .profile-page-box {
    width: calc(100% - 40px);
  }
}

.profile-page {
  padding: 20px;
}

.profile-header {
  margin-right: 20px;
}

.profile-image-large {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 1px solid #ccc;
}

.profile-info {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.input-row {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.input-row label {
  color: grey;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-row input {
  border: 0px solid rgb(184, 183, 183);
  border-radius: 5px;
  background-color: #e7e7e7;
  padding: 11px;
  margin-top: 5px;
}

.input-row:nth-child(1),
.input-row:nth-child(2) {
  flex: 2;
}

.input-row:nth-child(3),
.input-row:nth-child(4),
.input-row:nth-child(5) {
  flex: 1;
}

/* Adjust spacing between input fields */
.input-row:not(:last-child) {
  margin-right: 20px;
}

.input-row-2 {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.input-row-2 label {
  color: grey;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-row-2 input {
  border: 1px solid rgb(47, 46, 46);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 11px;
  margin-top: 5px;
}

.input-row-2:nth-child(1),
.input-row-2:nth-child(2),
.input-row-2:nth-child(3),
.input-row-2:nth-child(4) {
  flex: 1; /* Take up regular space */
}

.input-row-3 {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.input-row-3 label {
  color: grey;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-row-3 select {
  border: 1px solid rgb(47, 46, 46);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 11px;
  margin-top: 5px;
}

.profile-info-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.Profile-input {
  flex: 1 1 300px; /* flex-grow, flex-shrink, flex-basis */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.Profile-input h3 {
  color: #716f6f;
  margin-bottom: 10px;
}

.dropdown-input {
  display: flex;
}

.input-row-3 {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.input-row-3 label {
  color: grey;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-row-3 select {
  border: 1px solid rgb(47, 46, 46);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 11px;
  margin-top: 5px;
}

.work-lists,
.work-lists-2 {
  flex: 1 1 300px; /* flex-grow, flex-shrink, flex-basis */
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.work-list h3,
.work-list-2 h3 {
  color: #888888;
  margin-bottom: 10px;
  font-size: 18px;
}

.work-list ul,
.work-list-2 ul {
  padding: 0;
}

.work-list ul li,
.work-list-2 ul li {
  margin-bottom: 5px;
}

.profile-page-box {
  background-color: #ffffff;
}

.Input-group {
  margin-top: 15px;
  margin-left: auto; /* This pushes the button to the right */
}

.Upload-btn {
  background-color: #1ae71d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.Upload-btn:hover {
  background-color: #308f18;
}
/* Media Query for Mobile View */
@media (max-width: 768px) {
  .profile-info-3 {
    flex-direction: column;
    padding: 10px; /* Adjust padding for space between sections */
  }
}

/* CSS for dropdown lists */
.address-input,
.city-input,
.work-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  margin-top: 5px;
}

/* Custom dropdown arrow */
select::after {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Style the options inside the dropdown */
select option {
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

/* Hover effect on dropdown options */
select option:hover {
  background-color: #f5f5f5;
}

/* Style the focused option */
select option:focus {
  outline: none;
  background-color: #f0f0f0;
}

.profile-page-box {
  /* Your existing styles for the profile page box */

  .four-inputs-in-line {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .input-row {
      flex: 1;
      margin-right: 10px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }

      input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
    }
  }
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .profile-page-box {
    .four-inputs-in-line {
      flex-direction: column;

      .input-row {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .work-lists,
    .work-lists-2 {
      display: flex;
      flex-direction: column;

      .work-list,
      .work-list-2 {
        margin-bottom: 10px;
      }
    }
  }
}
