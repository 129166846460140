.cart-page{

  height: 100vh;
  padding: 20px;
}


.cart-headings{
  padding: 20px;
  border-bottom: 2px solid #00AC24;
  display: flex;
  align-items: center;

}

.cart-icon-payment{
  color: #00AC24;
  font-size: 50px;
  margin-right: 20px;
}

.cart-headings h1{
  color: #00AC24;
text-align: center;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.cart-item{
  border-bottom: 2px solid #00AC24;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.Cart-item-heading{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Cart-item-heading h1{
  margin-bottom: 0px;
}

.Cart-item-heading p{
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
background: #000;
  color: var(--white-100, #FFF);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 100% */
letter-spacing: -0.32px;
  width: 146px;
height: 26px;
flex-shrink: 0;
  margin-top: 0px;
}



.Cart-btn{
  align-items: center;
  display: flex;
  justify-content: center;
  width: 125px;
height: 40.909px;
flex-shrink: 0;
border-radius: 42.045px;
background: #00AC24;
}

.Cart-button{
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Cart-number{
  color: #F3F3F3;
font-size: 17.045px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


.Total-main{
  align-items: center;
  justify-content: space-between;
  border-radius: 82.765px;
background: #000;
  width: 363px;
height: 105.257px;
flex-shrink: 0;
  display: flex;
}

.Total-heading{
  margin-left: 50px;
}
.Total-heading p{
  margin: 0;
  color: var(--white-100, #FFF);
  font-family: Inter;
  font-size: 28.872px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pay-btn{
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  width: 159.23px;
height: 100.16px;
border-radius: 82.765px;

}

.pay-btn p{
  
  color: #000;
  font-size: 28.872px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Heading-pay {
  display: flex;
  align-items: center;
}

.Heading-pay input[type='checkbox'] {
  margin-right: 8px; 
}

.Heading-pay p {
  color: #000;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
  margin: 0; 
}


.Payment-container{
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}