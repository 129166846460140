.services-body {
  padding: 20px;
}

.services-heading {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 17px;
  margin-bottom: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 999;
}

.main-section-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 17px;
  background-color: #ffffff;
  border: px solid #bdbdbd;
  border-radius: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-container {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Adjust as needed */
  right: 0;
  width: 250px;
  background-color: #ffffff;
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.user-info {
  border: px solid #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-info:hover {
  background-color: #f5f2f2;
}
.user-info-content {
  display: flex;
  align-items: center;
}

.profile-image-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  margin: 0;
  font-weight: bold;
}

.user-subtitle {
  margin: 0;
  color: #888888;
}

.logout {
  display: flex;
  align-items: center;
}

.logout:hover {
  background-color: #edecec;
}
.logout svg {
  font-size: 15px;
  margin-right: 5px;
}

.logout p {
  margin: 0;
}

.separator {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #bdbdbd;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-content-icon {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}
.box-content-img {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}
/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .main-section-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 10px;
  }

  .services-heading h1 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .profile-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .dropdown-menu {
    width: 100%;
    max-width: 250px;
    padding: 15px;
    right: auto;
  }

  .user-info {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .profile-image-small {
    width: 25px;
    height: 25px;
    margin-right: 0;
  }

  .logout svg {
    font-size: 12px;
  }
}

/* secrive-box */
.Serive-container {
  margin-bottom: 30px;
  width: 1100px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.Serive-box {
  padding: 10px;
  border-radius: 24px;
  background-color: #0bf4c8;
  width: 300px;
  height: 170px;
}
.services-heading h1 {
  color: #575252;
  font-size: 20px;
}

.Serive-content {
  width: 302px;
  justify-content: space-between;

  display: flex;
}

.Headings-Boxs {
}

.Headings-Boxs h1 {
  margin-bottom: 0px;
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.image-box p {
  color: #131215;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-decoration-line: underline;
}

.image-box {
  width: 270px;
  align-items: center;
  margin-top: 0px;
  justify-content: space-between;
  display: flex;
}
.image-box img {
  width: 110px;
  height: 110px;
  flex-shrink: 0;
}
.Mobile-heading {
  color: #000;
  font-size: 20px;
}

.Choose-Headings {
  color: #00ac24;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}
.Dot-box {
  height: 130px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Box-circle {
  border-radius: 24px;
  background-color: #ffffff;
  width: 29px;
  height: 11px;
}
