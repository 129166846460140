.popup-slider {
    position: fixed;
    top: 0;
    right: 0;
    width: 460px;
    height: 100%;
    background-color: white;
    box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
  .popup-header h3 {
    border: px solid green;
    border-radius: 5px;
    font-size: 18px;
    background-color: rgb(138, 233, 138);
    margin: 0;
    padding: 10px; /* Increase the padding to expand the background */
  }
  
  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
  }
  
  .close-btn:hover {
    color: #333;
  }
  
  .popup-content {
    padding: 20px;
  }
  
  .popup-content p{
    text-align: left;
  }
  
  
  @media (max-width: 768px) {
    .popup-slider {
      width: calc(90% - 30px); 
      height: 100%;
      padding: 10px;
    }
  
    .popup-header h3 {
      font-size: 16px;
      padding: 8px;
    }
    .close-btn {
      margin-right: 50px; /* Move the close button 10px to the left */
    }
    .popup-content {
      padding: 10px;
    }
  }