
  .Dashboard-container{
    border-radius: 30px;
    background-color: #f7f7f7;
  }
.BiCodeBlock,.BsStar{
  
  font-size: 30px;
}

  .dashboard-icon-navbar{
    width: 200px;
    display: flex;
    align-items: center;
   justify-content: space-between;
  }
  .dashboard2-heading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 20px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999; 
  }
  
  
  .dashboard2-heading h1 {
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-size: 20px;
  }
  
  .BiSearch{
    color: var(--black-20, rgba(28, 28, 28, 0.20));
    font-size: 30px;
  }
  .input-navbar-heading input{
    font-size: 20px;
    color: var(--black-20, rgba(28, 28, 28, 0.20));
    border: none;
    width: 210px;
    height: 40px;
  }



  .input-navbar-heading input[type="search"] {
    border: none; 
    padding: 5px;
}

.input-navbar-heading input[type="search"]:focus {
    outline: none; 
    border: 1px solid transparent; 
    box-shadow: none; 
}


.Right-heading{
  align-items: center;
  justify-content: space-between;
  width: 390px;
  display: flex;
}
.IoNotificationsOutline{
  font-size: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 38.958px;
background: #F3F3F3;
box-shadow: 0px 2.833px 4.25px 0px rgba(0, 0, 0, 0.02);
  width: 50px;
  height: 50px;
}
  .input-navbar-heading{
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 50px;
    border: 1px solid rgb(176, 182, 176);
    width: 250px;
  }
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .profile-container {
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%; 
    right: 0;
    width: 250px; 
    background-color: #ffffff;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .user-info {
    border: px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .user-info:hover{
    background-color: #f5f2f2;
  }
  .user-info-content {
    display: flex;
    align-items: center;
  }
  
  .profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-name {
    margin: 0;
    font-weight: bold;
  }
  
  .user-subtitle {
    margin: 0;
    color: #888888; 
  }
  
  .logout {
    display: flex;
    align-items: center;
  }
  
  .logout:hover{
    background-color: #edecec;
  }
  .logout svg {
    font-size: 15px;
    margin-right: 5px;
  }
  
  .logout p {
    margin: 0;
  }
  
  .separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
  }
  .box-container {
    display: flex;
    margin-top: 20px;
  }
  
  .box {
    border-radius: 14px;
    background: var(--white-100, #FFF);
     box-shadow: 10px 10px 64px 0px rgba(0, 0, 0, 0.05);
      width: 350px;
      height: 161px;
    flex-shrink: 0;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
   padding: 10px;
   margin-bottom: 40px;
  }

.Dashboard2-heading h2{
    margin-bottom: 40px;
}


.Box-heading{
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.HiMiniUsers{
 color: #ffffff;
  font-size: 30px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(130, 128, 255, 1);
  width: 60px;
height: 60px;
flex-shrink: 0;
}

.Box-heading h1{
  color: #202224;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
}

.box p{
  margin-bottom: 30px;
  color: #202224;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.Box-heading-two{
  display: flex;
}

.TbArrowWaveRightUp{
  color: #00B69B;
  font-size: 30px;
  margin-right: 10px;
}

.Box-heading-two span{
  color: #00B69B;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.Box-heading-two p{
color: #606060;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}






/* table*/
.table-container-Dashboard {
  background-color: transparent;
  border: px solid #ffffff; 
  padding: 20px;
  }
  
  .data-table-Dashboard {
    width: 100%;
    border-collapse: collapse;
    border: px solid #ccc;
  }
  
  
  .data-table-Dashboard td {
    height: 100px;
    background-color: #ffffff;
    color: #b1aeae;
    padding: 8px;
    border: none; 
    border-bottom: 1px solid #ccc;
  }
  
  .data-table-Dashboard th {
    height: 70px;
    color: #202224;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8px;
    border: none; 
    text-align: left;
    background-color: #F1F4F9;
  }

  
  .data-table-Dashboard tbody tr:hover {
    background-color: #eeeeee;
  }
 

  .table-btn{
    width: 80.742px;
height: 23.441px;
flex-shrink: 0;
  }

  .Complete-btn{
    border: none;
    border-radius: 11.721px;
background: #00B69B;
  }

  .Pending-btn{
    border: none;
    border-radius: 11.721px;
background: #FCBE2D;
  }

  .Rejected-btn{
    border: none;
    border-radius: 11.721px;
background: #FD5454;
  }

  .img-table{
    align-items: center;
    display: flex;
    gap: 10px;
  }

  .FcCheckmark{
    font-size: 40px;
  }

  .top-headings{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top-headings h1{
   
    color: #202224;
font-size: 20.837px;
font-style: normal;
font-weight: 700;
line-height: 17.364px; /* 83.333% */
  }

  .top-headings p{
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 20px;
    border-radius: 3.473px;
border: 0.521px solid #D5D5D5;
background: #FCFDFD;
    width: 100px;
height:35px;
flex-shrink: 0;
  }


  .dropdown-select {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 20px;
    border-radius: 3.473px;
    border: 0.521px solid #D5D5D5;
    background: #FCFDFD;
    width: 120px;
    height: 35px;
    flex-shrink: 0;
    padding: 5px;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    .data-table-Dashboard {
      overflow-x: auto;
      display: block;
      width: 100%;
      table-layout: fixed; 
    }
    
    .data-table-Dashboard th,
    .data-table-Dashboard td {
      white-space: nowrap;
      min-width: 90px;
    }
    .project-heading{
    width: calc(90% - 40px); 
    }
  }
  
  