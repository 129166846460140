* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}

main {
  width: 100%;
  padding: 10px;
}
.container {
  width: -webkit-fill-available;
}
.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.sidebar {
  min-height: 100vh;
  background: rgb(213, 213, 213);
  color: rgba(73, 85, 111, 1);
  box-shadow: 4px 0 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.img-logo {
  margin-left: 30px;
  height: 90px;
  font-size: 18px;
  line-height: 0;
}

.bars {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(
    0deg,
    #11d103 0%,
    #60e16d 26.68%,
    #91f397 73.61%,
    #bfefc3 100%
  );
  width: 40px;
  height: 37px;
}

.hide {
  display: none;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.link {
  margin-left: 19px;
  align-items: center;
  width: 240px;
  height: 49px;
  display: flex;
  color: rgba(73, 85, 111, 1);
  gap: 10px;
  padding-left: 10px;
  padding: 1px px;
  border-radius: 15px;
  border-right: 1px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  color: rgb(253, 253, 253);
  border-right: 1px solid white;
  background: rgba(55, 81, 242, 1);
}

.link_text {
  white-space: nowrap;
  font-size: 16px;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.image-profile {
  margin-left: 20px;
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.profile-content {
  font-size: 16px;
}

/* Add this CSS to your existing styles */
.sub-items {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.sub-item {
  width: 220px;
  border-radius: 10px;
  padding: 10px;
  color: #313030;
  text-decoration: none;
}

.sub-item:hover {
  color: #fff;
  background-color: rgb(98, 223, 98);
}

.active {
  color: #fff;
  background-color: rgb(98, 223, 98);
}
.items-icon {
  margin-right: 20px;
}

/* For mobile devices */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    min-width: 0;
  }
  .top_section {
    padding: 10px;
  }
  .bars {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
  .sidebar.open {
    width: 250px;
  }
  .routes {
    margin-top: 10px;
  }
  .link {
    margin-left: 0;
    width: 100%;
    justify-content: flex-start;
    padding-left: 20px;
  }
  .menu {
    padding: 5px;
    padding-right: 10px;
  }
  .menu_item {
    gap: 5px;
  }
  .menu_container .link {
    padding-left: 30px;
  }
  .image-profile {
    margin-left: 10px;
  }
  .sub-items {
    margin-left: 0;
  }
  .sub-item {
    width: 100%;
    padding: 10px 20px;
  }
}
