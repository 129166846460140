.timesheet-body {
    padding: 20px;
}

.timesheet-heading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999; /* Ensures it stays above other content */
}


.timesheet-heading h1 {
    color: #575252;
    font-size: 20px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-container {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Adjust as needed */
    right: 0;
    width: 250px; 
    background-color: #ffffff;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.user-info {
    border: px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.user-info:hover{
    background-color: #f5f2f2;
}
.user-info-content {
    display: flex;
    align-items: center;
}

.profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    margin: 0;
    font-weight: bold;
}

.user-subtitle {
    margin: 0;
    color: #888888; /* Subtitle color */
}

.logout {
    display: flex;
    align-items: center;
}

.logout:hover{
    background-color: #edecec;
}
.logout svg {
    font-size: 15px;
    margin-right: 5px;
}

.logout p {
    margin: 0;
}

.separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
}



@media screen and (max-width: 768px){
    .timesheet-heading{
     width: calc(100% - 40px); 
    }
}



.hours-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px; /* Rounded corners for the box */
    margin-top: 20px; /* Margin from the top */
  }
  
  .hours-info {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .hours-heading {
    font-weight: bold;
    margin: 0; 
    display: inline; 
    margin-right: 20px; 
  }
  
  
  .hours-section span {
    color: rgb(235, 98, 48);
    font-weight: normal;
  }
  

  .add-more-button {
    font-size: 15px;
    background-color: #86eb6d; /* Button background color */
    color: white; /* Button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-more-button:hover {
    background-color: #09ac17; /* Hover background color */
  }

  /* Mobile view styles */
@media screen and (max-width: 768px) {


   .hours-section{
    width: calc(100% - 40px); 
   }

    .hours-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .hours-heading {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .add-more-button {
        font-size: 10px;
        align-self: flex-end;
    }
}


.combined-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f9f9f9;
    border: px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
}


.button-row span {
    color: rgb(235, 98, 48);
    font-weight: normal;
  }
  
.button-row {
    display: flex;
    align-items: flex-start; /* Align buttons to the left */
    margin-top: 20px;
}

.button-wrapper {
    text-align: center;
    margin-right: 10px; /* Add some space between buttons */
}

.button-wrapper:last-child {
    margin-right: 0; /* Remove margin from last button */
}

.button-wrapper p {
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
}
.more-button {
    font-size: 14px;
    background-color: #e8e8e8; /* Button background color */
    color: rgb(130, 128, 128); /* Button text color */
    border: none;
    padding: 8px 16px;
    border-radius: 3px;
    cursor: pointer;
}
.dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Style the dropdown links */
  .dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .dropdown-content p:hover {
    background-color: #f1f1f1;
  }
  



.more-button:hover {
    background-color: #f1efef; /* Hover background color */
}

.more-button svg {
    margin-left: 7px; /* Adjust the icon spacing */
}

.text-section-inline {
    display: inline-block;
    margin-right: 20px; /* Adjust margin as needed */
  }
@media screen and (max-width: 768px) {
    .combined-section{
    width: calc(100% - 40px); 
    }
    .button-row {
        flex-direction: column;
        align-items: flex-start; /* Align buttons to the left in mobile view */
        margin-top: 20px;
    }

    .button-wrapper {
        text-align: left; /* Align buttons to the left */
        margin-right: 0; /* Reset margin for mobile view */
        margin-bottom: 10px;
    }

    .button-wrapper:last-child {
        margin-right: 0; /* Remove margin from last button */
    }

    .button-wrapper p {
        font-weight: bold;
        text-align: left; /* Align text to the left */
        font-size: 14px;
        margin-bottom: 5px; /* Adjust spacing */
    }

    .more-button {
        font-size: 14px;
        background-color: #e8e8e8; /* Button background color */
        color: rgb(130, 128, 128); /* Button text color */
        border: none;
        padding: 8px 16px;
        border-radius: 3px;
        cursor: pointer;
        width: 100%; /* Full width for mobile */
        text-align: left; /* Align text to the left */
    }

    .more-button:hover {
        background-color: #f1efef; /* Hover background color */
    }

    .more-button svg {
        margin-left: 7px; /* Adjust the icon spacing */
    }

    .text-section-inline {
        display: block;
        margin-right: 0; /* Reset margin for mobile view */
        margin-bottom: 10px;
    }
}


.checkbox-section {
    display: flex;
    margin-left: 40px;
    display: inline-block;
    margin-top: 10px;
  }
.flex{
    display: flex;
}
  
  .checkbox-heading {
    text-align: left;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: bold;
  }
  
  .checkbox-label {
    display: inline-block;
    margin-right: 30px; 
  }
  
  .checkbox-label input[type="checkbox"] {
    display: none; 
  }
  
  .checkbox-label span.checkbox-custom {
    margin-right: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ffffff; 
    border: 2px solid #3ae634; 
    border-radius: 50%; 
    position: relative;
    cursor: pointer;
  }
  
  .checkbox-label span.checkbox-custom::before {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    background-color: #4fcf2b; 
    border-radius: 50%; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0); /* Initially scale(0) to hide */
    transition: transform 0.2s ease-in-out; /* Transition effect */
  }
  
  .checkbox-label input[type="checkbox"]:checked + span.checkbox-custom::before {
    transform: translate(-50%, -50%) scale(1); /* Show the checked circle */
  }
  

  .checkbox-label span{
    color: #575252;
  }


/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .editor-section{
        width: calc(100% - 40px); 
    }

    .checkbox-section {
      margin-left: 0;
      text-align: left;
    }
  
    .checkbox-label {
      display: block;
      margin: 5px 0;
    }
  }


/* Timesheet.css */
.editor-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.editor-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.editor-container {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.editor-wrapper {
    height: 200px;
    border: px solid #ccc;
    padding: 5px;
    background: #fff;
    overflow-y: auto;
}

.editor-content {
    height: 100%;
    padding: 10px;
}


/* Styles for the custom button */
.custom-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #23d978;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-button:hover {
    background-color: #078311;
}

.custom-button:active {
    transform: translateY(1px);
}

.custom-button:focus {
    outline: none;
    /* Add your focus styles here */
}

.custom-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
