

.notice-body {
    padding: 20px;
  }
  
  .notice-heading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .notice-heading h1 {
    color: #575252;
    font-size: 20px;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .profile-container {
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .user-info {
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .user-info:hover {
    background-color: #f5f2f2;
  }
  
  .user-info-content {
    display: flex;
    align-items: center;
  }
  
  .profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-name {
    margin: 0;
    font-weight: bold;
  }
  
  .user-subtitle {
    margin: 0;
    color: #888888;
  }
  
  .logout {
    display: flex;
    align-items: center;
  }
  
  .logout:hover {
    background-color: #edecec;
  }
  
  .logout svg {
    font-size: 15px;
    margin-right: 5px;
  }
  
  .logout p {
    margin: 0;
  }
  
  .separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
  }
  
  .Notice-search-frame {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 10px;
  }
  
  .search-status-type {
    display: flex;
    align-items: center;
  }
  
  .dropdown-container {
    position: relative;
  }
  
  .status-button,
  .type-button {
    padding: 8px 16px;
    border: 1px solid #d9dbda;
    border-radius: 4px;
    background-color: #f4f4f4;
    color: #000000;
    cursor: pointer;
    margin-right: 5px;
    width: auto; /* Change to auto width */
  }
  
  .status-button:last-child,
  .type-button:last-child {
    margin-right: 0;
  }
  
  .status-dropdown-2 {
    position: absolute;
    top: calc(100% + 5px);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    min-width: 80px;
    max-width: 150px;
    left: 0;
  }
  
  .type-dropdown-2 {
    position: absolute;
    top: calc(100% + 5px);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    min-width: 80px;
    max-width: 150px;
    width: max-content;
    left: 0;
  }
  
  .status-dropdown-2 ul,
  .type-dropdown-2 ul {
    margin: 0;
    padding: 0; /* Adjusted to remove padding */
    max-height: 150px;
    overflow-y: auto;
  }
  
  .status-dropdown-2 ul li,
  .type-dropdown-2 ul li {
    list-style-type: none;
    font-size: 13px;
    padding: 8px 12px; /* Padding for individual items */
    cursor: pointer;
  }
  
  .status-dropdown-2 ul li:hover,
  .type-dropdown-2 ul li:hover {
    background-color: #f2f2f2;
  }
  
  .dropdown-search-input {
    width: 100%;
    padding: 7px 5px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dropdown-search-input-1{
    width: 100%;
    padding: 7px 5px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  .date-icon {
    color: #888888;
  }
  
  .angle-down-icon {
    color: #b2b3b4;
    font-size: 20px;
  }
  
  .date-dropdown-content-notice {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    max-width: 180px; 
    left: calc(0% + 1px); /* Move the dropdown to the right side */
    top: 0;
  }
  
  .date-dropdown-content-notice ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .date-dropdown-content-notice li {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .date-dropdown-content-notice li:hover {
    background-color: #f0f0f0;
  }
  
  .custom-date-options {
    position: absolute;
    top: 0;
    left: calc(100% + 30px);
    display: flex;
    align-items: center;
  }
  
  .date-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin-right: 10px;
  }
  
  .date-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .apply-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .apply-button:hover {
    background-color: #0056b3;
  }
  
  .table-container {
    margin-top: 20px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th,
  .data-table td {
    color: #b1aeae;
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }
  
  .data-table th {
    color: #9a9595;
    text-align: left;
    background-color: #f6f5f5;
  }
  
  .data-table tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .data-table tbody tr:hover {
    background-color: #eeeded;
  }
  
  @media screen and (max-width: 768px) {
    .Notice-search-frame {
      width: calc(95% - 40px);
    }
  
    .status-dropdown-2,
    .type-dropdown-2 {
      left: 0;
      min-width: 100px; /* Change to desired min-width */
      max-width: 80%;
      margin-bottom: 8px;
    }
  
    .status-button,
    .type-button {
      width: 100%;
      margin-bottom: 8px;
    }
  
    .status-button:last-child,
    .type-button:last-child {
      margin-bottom: 0;
    }
  
    .search-status-type {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-container {
      margin-bottom: 8px;
    }
  
    .notice-heading {
      width: calc(90% - 40px);
    }
  }
  