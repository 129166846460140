.add-timesheet-body {
    padding: 20px;
}

.add-timesheet-heading{
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999; /* Ensures it stays above other content */
}


.add-timesheet-heading h1 {
    color: #575252;
    font-size: 20px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-container {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Adjust as needed */
    right: 0;
    width: 250px; 
    background-color: #ffffff;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.user-info {
    border: px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.user-info:hover{
    background-color: #f5f2f2;
}
.user-info-content {
    display: flex;
    align-items: center;
}

.profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    margin: 0;
    font-weight: bold;
}

.user-subtitle {
    margin: 0;
    color: #888888; /* Subtitle color */
}

.logout {
    display: flex;
    align-items: center;
}

.logout:hover{
    background-color: #edecec;
}
.logout svg {
    font-size: 15px;
    margin-right: 5px;
}

.logout p {
    margin: 0;
}

.separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
}

@media screen and (max-width: 768px){
    .add-timesheet-heading{
     width: calc(90% - 40px); 
    }
}


/* Style for the form buttons */

.add-timesheet-form {
    background-color: #ffffff;
    border: px solid #ffffff; /* Border color and size */
    border-radius: 3px;
    padding: 20px;
    margin-top: 20px;
}
.form-button {
    position: relative;
    background-color: #efeded;
    color: rgb(130, 125, 125);
    border: none;
    padding: 15px 35px 15px 30px;
    margin-right: 14px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.form-button:hover {
    background-color: #dbdbdc;
}

/* Style for the date icon container */
.date-icon-container {
    position: relative;
    display: inline-block;
}

/* Style for the date icon */
.date-icon {
    color: #adadad;
}

/* Style for the angle down icon */
.angle-down-icon {
    position: absolute;
    top: 40%;
    right: 10;
    transform: translateY(-50%);
    color: #bebebe;
}

.down-icon{
    margin-left: 10px;
}



/* Style for the angle down icon */
.angle-down-icon {
    position: absolute;
    top: 40%;
    right: 10;
    transform: translateY(-50%);
    color: #b4b5b6;
}

.dropdown-content {
    position: absolute;
    background-color: #fefefe;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    display: none;
}

/* Adjust the position for each dropdown */
.dropdown-container {
    position: relative;
    display: inline-block;
    margin-right: 10px; /* Adjust spacing between buttons */
}

.dropdown-container .form-button {
    margin-right: 0; /* Reset margin for the button */
}


.date-dropdown{
    top: 25%;
    left: 50%; /* Updated left position to move it to the right */
    transform: translateX(-1%);
}
/* Display the active dropdown */
.dropdown-content.active {
    background-color: #fffdfd;
    display: block;
}

.dropdown-content ul {
   
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-content li {
    color: #575252;
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-content li:hover {
    background-color: #ddd;
}

.add-timesheet-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-timesheet-button {
    background-color: #ffffff;
    color: rgb(79, 227, 35);
    border: 1px solid rgb(42, 205, 42);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto; /* Moves the button to the right */
}

.add-timesheet-button:hover {
    color: #ffffff;
    background-color: #56d82e;
}

/* Styles for mobile view */

@media (max-width: 768px) {
    .add-timesheet-form{
        width: calc(90% - 40px); 
    }
}

@media (max-width: 768px) {
    /* Adjust the date icon and angle down icon */
    .angle-down-icon {
        right: 5px;
    }
    /* Set the form buttons to stack vertically */
    .form-button {
        width: calc(140% - 40px); /* Adjust the width as needed */
        margin-right: 0;
        margin-bottom: 10px;
    }

    .add-timesheet-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .form-button {
        padding-left: 20px;
    }
    .form-button:last-child {
        margin-bottom: 0;
    }

    

    .date-dropdown.active {
        left: auto;
        right: 0; /* Move to the right side */
        width: 0%; /* Adjusted width */
        top: 35%; /* Adjusted top position */
        transform: translateX(-30%); /* Center horizontally */
    }
    /* Added to stack buttons in a single column */
   
    .add-timesheet-form {
        display: flex;
        flex-direction: column;
        align-items: left; /* Center items horizontally */
    }
    .add-timesheet-button {
        margin-left: 0; /* Move to the left */
        margin-right: 20px; /* Add some right margin for spacing */
    }
    .angle-down-icon {
        left: 20px; /* Add space between the icons */
    }
   
}



.section-box-2 {
    background-color: #ffffff;
    border: 1px solid #fffefe;
    border-radius: 2px;
    padding: 20px;
    margin-top: 2px;
}

.new-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.new-button {
    background-color: #ffffff;
    color: rgb(79, 227, 35);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.new-button:hover {
    color: #ffffff;
    background-color: #56d82e;
}
.active {
    color: #ffffff;
    background-color: #56d82e;
}



.switch-container {
    display: flex;
    align-items: center;
    margin-left: auto; /* Move switch to the right */
}

.switch-label {
    margin-right: 10px; /* Adjust spacing between switch and text */
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 15px; /* Adjust the border-radius to make it round */
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px; /* Adjust the height of the slider button */
    width: 15px; /* Adjust the width of the slider button */
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; /* Make the slider button round */
}

input:checked + .slider {
    background-color: #2ff321;
}

input:checked + .slider:before {
    transform: translateX(15px); /* Move the slider button to the right */
}



/* Styles for the table */
.timesheet-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow-x: auto; /* Enable horizontal scrolling */
}

/* Styles for table headings */
.timesheet-table th {
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

/* Styles for table rows */
.timesheet-table td {
    border-bottom: 1px solid #ccc;
    padding: 8px;
}

/* Responsive styles for mobile view */

/* Responsive styles for mobile view */
@media screen and (max-width: 600px) {
    
    .timesheet-table-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS devices */
        -ms-overflow-style: -ms-autohiding-scrollbar; /* Hides scrollbars on IE/Edge */
    }

    .timesheet-table th,
    .timesheet-table td {
        white-space: nowrap; /* Prevent wrapping of table cells */
        min-width: 100px; /* Minimum width for cells */
    }
}

@media screen and (max-width: 768px) {
    .section-box-2 {
        padding: 10px;
        width: calc(95% - 40px); 
    }

    .new-section {
        flex-wrap: wrap;
    }

    .new-button {
        flex-basis: calc(50% - 5px);
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .switch-container {
        margin-top: 10px;
    }

    .switch-label {
        margin-right: 5px;
    }

}



