.dashboard-body {
  padding: 20px;
}

.dashboard-heading {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 17px;
  margin-bottom: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 999; 
}


.dashboard-heading h1 {
  color: #575252;
  font-size: 20px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-container {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Adjust as needed */
  right: 0;
  width: 250px; 
  background-color: #ffffff;
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.user-info {
  border: px solid #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-info:hover{
  background-color: #f5f2f2;
}
.user-info-content {
  display: flex;
  align-items: center;
}

.profile-image-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  margin: 0;
  font-weight: bold;
}

.user-subtitle {
  margin: 0;
  color: #888888; /* Subtitle color */
}

.logout {
  display: flex;
  align-items: center;
}

.logout:hover{
  background-color: #edecec;
}
.logout svg {
  font-size: 15px;
  margin-right: 5px;
}

.logout p {
  margin: 0;
}

.separator {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #bdbdbd;
}

/* New Section Styles */
.common-heading h2 {
  text-align: left !important;
  margin-bottom: 20px;
  font-size: 20px;
  color: #969595;
}
.section-container {
  margin-bottom: 20px;
  display: flex;
}

.section-box {
  height: 150px;
  flex: 0.25; /* Decreased the width */
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 4px;
  margin-right: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(47, 198, 27, 0.1);
  transition: box-shadow 0.3s ease;
}

.section-box:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 15px 20px rgba(80, 216, 43, 0.3);
}

.section-box p {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.section-box h2 {
  margin: 0;
}

.box-content {
  text-align: center;
}

.box-number {
  color: rgb(78, 210, 78);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px; /* Added margin-top */
}

@media only screen and (max-width: 768px) {
  .section-container {
    flex-direction: column;
  }

  .section-box {
      width: 300px;
    flex: 1;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* new section-2 */

.new-common-heading h2 {
  text-align: left !important;
  margin-bottom: 20px;
  font-size: 20px;
  color: #969595;
}
.new-section-container {
  margin-bottom: 20px;
  display: flex;
}

.new-section-box {
  height: 130px;
  flex: 0.25; /* Decreased the width */
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 4px;
  margin-right: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(50, 234, 240, 0.1), 0px 10px 10px rgba(13, 17, 222, 0.1);
  transition: box-shadow 0.3s ease;
}

.new-section-box:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(11, 219, 219, 0.1), 0px 6px 6px rgba(37, 118, 216, 0.3);
}

.new-section-box p {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.new-section-box h2 {
  margin: 0;
}

.new-box-content {
  text-align: center;
}

.new-box-number {
  color: rgb(50, 223, 232);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px; /* Added margin-top */
}
@media only screen and (max-width: 768px) {
  .new-section-container {
    flex-direction: column;
  }

  .new-section-box {
      width: 300px;
    flex: 1;
    margin-right: 0;
    margin-bottom: 10px;
  }
}


/* new section-3 */

.Generic-common-heading h2 {
  text-align: left !important;
  margin-bottom: 20px;
  font-size: 20px;
  color: #969595;
}
.Generic-section-container {
  margin-bottom: 50px;
  display: flex;
}

.Generic-section-box {
  flex: 0.25; /* Decreased the width */
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 45px;
  margin-right: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(225, 139, 26, 0.1);
  transition: box-shadow 0.3s ease;
}

.Generic-section-box:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 6px 6px rgba(217, 108, 18, 0.3);
}

.Generic-section-box p {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.Generic-section-box h2 {
  margin: 0;
}

.Generic-box-content {
  text-align: center;
}

.Generic-box-number {
  color: rgb(232, 89, 37);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px; /* Added margin-top */
}

.Generic-box-content .extra{
  color: rgb(232, 89, 37);
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  .Generic-section-container {
    flex-direction: column;
  }

  .Generic-section-box {
    width: 220px;
    flex: 1;
    margin-right: 0;
    margin-bottom: 10px;
  }
}


/* 
section4 */

.section-container-three {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between; /* Updated to evenly space the boxes */
}



.section-box-three {
  flex: 1; /* Each box takes up equal width */
  border: px solid #bdbdbd;
  border-radius: 7px;
  padding: 90px;
  height: 200px;
  width: 340px;
  height: 100px;
  margin-right: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(10, 10, 9, 0.1);
}

.box-content-three p {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.box-content-three {
  text-align: center;
}

.header-box-container h2{
  color: #888888;
  display: block;
  font-size: 20px;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

@media only screen and (max-width: 768px) {
  .section-container-three {
    flex-direction: column;
  }

  .section-box-three {
    width: 120px;
    flex: 1;
    margin-right: 0;
    margin-bottom: 10px;
  }
}