.Request-container{
    width: 100%;
    height: 100vh;
}

.Request-main{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Reuest-box{
    margin-top: 100px;
    margin-bottom: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
background: #00AC24;
    width: 441px;
height: 309px;
flex-shrink: 0;
}

.Request-main button{
    color: #33cb29;
font-family: "Nunito Sans";
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
    width: 441px;
height: 64px;
flex-shrink: 0;
border-radius: 10px;
background: #000000;
}

.Reuest-box h1{
    width: 300px;
color: var(--white-100, #FFF);
text-align: center;
font-family: "Nunito Sans";
font-size: 44.485px;
font-style: normal;
font-weight: 700;
line-height: 136.134%; /* 60.56px */
}


.FaBusinessTime{
    width: 68px;
height: 68px;
flex-shrink: 0;
color: #FFF;
}