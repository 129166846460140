.project-body {
    padding: 20px;
}

.project-heading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999; 
}


.project-heading h1 {
    color: #575252;
    font-size: 20px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-container {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px; 
    background-color: #ffffff;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.user-info {
    border: px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.user-info:hover{
    background-color: #f5f2f2;
}
.user-info-content {
    display: flex;
    align-items: center;
}

.profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    margin: 0;
    font-weight: bold;
}

.user-subtitle {
    margin: 0;
    color: #888888; 
}

.logout {
    display: flex;
    align-items: center;
}

.logout:hover{
    background-color: #edecec;
}
.logout svg {
    font-size: 15px;
    margin-right: 5px;
}

.logout p {
    margin: 0;
}

.separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
}


/* section-2 */
.search-frame {
    background-color: #ffffff;
    border: px solid #ffffff; 
    padding: 10px;
  }
  
.search-status-type {
    display: flex;
    align-items: center;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    margin-right: 10px; 
  }
  
  .search-input {
    background-color: #f4f4f4;
    padding: 8px;
    border: 1px solid #dedada;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .icon-search-wrapper {
    display: inline-flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 20px; 
    margin-right: 10px;
    height: 33px;
    width: 30px;
    border: 1px solid #dedada;
    border-radius: 2px;
    color: #555;
    cursor: pointer;
  }
  
  .status-button,
  .type-button {
    padding: 8px 16px;
    border:1px solid #d9dbda;
    border-radius: 4px;
    background-color: #f4f4f4;
    color: #000000;
    cursor: pointer;
  }
  
  .status-button {
    margin-right: 5px;
  }
  
  .type-button {
    margin-right: 10px;
  }

  .button-reset {
    padding: 8px 16px;
    border:1px solid #1ddd4a; 
    border-radius: 4px;
    background-color: #ffffff; 
    color: #49be0f;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }

  .button-reset:hover {
    color: #ffffff;
    background-color: #44d664; 
    border-color: #218838;
  }
  
  .reset-button:focus {
    outline: none;
    border-color: #218838; 
  }




.dropdown-project.show {
    display: block;
}

.dropdown-project ul {
    list-style-type: none;
    padding: 0;
}

.dropdown-project ul li {
  font-size: 14px;
    padding: 10px;
    cursor: pointer;
}

.dropdown-project ul li:hover {
    background-color: #f0f0f0;
}

.dropdown-project {
    display: none;
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: auto; 
    min-width: 115px; 
    max-width: 140px; 
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 8px;
    z-index: 1;
    overflow: hidden; 
}

.dropdown-search-input {
    width: auto; 
    min-width: 120px;
    max-width: 106px; 
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

  /* Media query for mobile view */
@media (max-width: 768px) {
  .search-frame{
    width: calc(95% - 40px); 
  }
  .table-container-project{
    width: calc(95% - 40px); 
  }

  
  .reset-button {
    width: 30%;
  }

  .dropdown-search-input{
    width: 10%;
    max-width: 100%;
  }
    .search-status-type {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-container {
      margin-bottom: 8px;
    }
  }
  

  /* Add this to your Project.css file */
.table-container-project {
  background-color: #ffffff;
  border: px solid #ffffff; /* Border around the frame */
  padding: 10px;
  }
  
  .data-table-project {
    width: 100%;
    border-collapse: collapse;
    border: px solid #ccc;
  }
  
  .data-table-project th,
  .data-table-project td {
    background-color: #ffffff;
    color: #b1aeae;
    padding: 8px;
    border: none; /* Remove border from all sides */
    border-bottom: 1px solid #ccc;
  }
  
  .data-table-project th {
    color: #9a9595;
    text-align: left;
    background-color: #eaeaeb;
  }

  
  .data-table-project tbody tr:hover {
    background-color: #eeeeee;
  }
 
  @media screen and (max-width: 768px) {
    .data-table-project {
      overflow-x: auto;
      display: block;
      width: 100%;
      table-layout: fixed; /* Added to ensure fixed layout */
    }
    
    .data-table-project th,
    .data-table-project td {
      white-space: nowrap;
      min-width: 90px;
    }
    .project-heading{
    width: calc(90% - 40px); /* Adjust for padding */
    }
  }
  
  