.Page1-container {
  width: 100%;
  height: 100vh;
}

.AiOutlineShoppingCart {
  font-size: 40px;
}
.ShoppingCart-icon{
  cursor: pointer;
}
.Page1-Right-heading {
  align-items: center;
  justify-content: space-between;
  width: 500px;
  display: flex;
}

.Page1-Main {
  padding: 20px;
}
.category-list {
  display: flex;
  gap: 10px;
}
.Page1-Main h1 {
  margin-bottom: 20px;
  color: #00ac24;
  text-align: left;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Page1-line {
  margin-bottom: 50px;
  background: #00ac24;
  height: 3px;
}

/* payment-box */
.Payment-box {
  padding: 20px;
  width: 374.163px;
  height: auto;
  flex-shrink: 0;
  border-radius: 19.066px;
  background: #e3e1e1;
}

.Payment-headings {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.Payment-headings h1 {
  color: #00ac24;
  font-size: 21.449px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ShoppingCart-icon {
  font-size: 40px;
}

.Price-para {
  margin-bottom: 10px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.32px;
  text-align: left;
}

.para-main {
  text-align: left;
  color: #787486;
  font-size: 14.299px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Quantity-box {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.Quantity-control {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 24.588px;
  background: #000;
  width: 190px;
  height: 31.449px;
  flex-shrink: 0;
  border: 1px solid rgb(0, 0, 0);
}

.Quantity-button {
  background-color: transparent;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.Quantity-number {
  font-size: 16px;
}
.Quantity-btn {
  margin-right: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 21.157px;
  background: #fafafa;
  width: 62.899px;
  height: 20.585px;
  flex-shrink: 0;
}

.Total-price {
  margin-top: 25px;
  color: var(--white-100, #fff);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cart-icon-container {
  position: relative;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}
