.leave-body {
    padding: 20px;
  }
  
  .leave-heading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .leave-heading h1 {
    color: #575252;
    font-size: 20px;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .profile-container {
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .user-info {
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .user-info:hover {
    background-color: #f5f2f2;
  }
  
  .user-info-content {
    display: flex;
    align-items: center;
  }
  
  .profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-name {
    margin: 0;
    font-weight: bold;
  }
  
  .user-subtitle {
    margin: 0;
    color: #888888;
  }
  
  .logout {
    display: flex;
    align-items: center;
  }
  
  .logout:hover {
    background-color: #edecec;
  }
  
  .logout svg {
    font-size: 15px;
    margin-right: 5px;
  }
  
  .logout p {
    margin: 0;
  }
  
  .separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
  }
  
  @media screen and (max-width: 768px) {
    .leave-heading {
        width: calc(90% - 40px);
    }
  }
  
  .main-section {
    display: flex;
    justify-content: space-between;
  }
  
  .leave-section {
    width: 48%;
  }
  
  .main-section {
    background-color: #ffffff;
    padding: 20px;
    border: px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .left-paragraph {
    margin-top: 10px;
    font-size: 17px;
    color: rgb(30, 176, 217);
    text-align: left;
    margin-bottom: 10px;
  }
  
  .leave-form {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    height: 600px; /* Adjust this value as needed */
    margin: 0 auto; /* Center the form horizontally */
  }
  .leave-form {
    display: flex;
    flex-direction: column;
    width: 100%; /* Full width */
    margin: 0;
    box-shadow: none;
  }
  
  .form-group {
    margin-bottom: 25px;
  }
  
  .form-group .form-label {
    color: #767474;
  }
  
  .form-group label {
    color: #575252;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group label span {
    color: orangered;
  }
  
  .form-group select,
  .form-group textarea,
  .form-group input[type="date"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
  }
  
  .form-group select:focus,
  .form-group textarea:focus,
  .form-group input[type="date"]:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .checkboxes {
    display: flex;
    align-items: center;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    white-space: nowrap;
  }
  
  .checkbox-item input[type="checkbox"] {
    position: relative;
    margin-right: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #ccc;
  }
  
  .checkbox-item input[type="checkbox"]:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .checkbox-item input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
  }
  
  .checkbox-item .option {
    font-weight: normal;
  }
  
  .checkbox-item label {
    font-weight: normal;
    font-size: 15px;
    margin-left: 5px;
  }
  
  .Save-btn {
  
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
  }
  
  .Save-btn:hover {
    background-color: #0056b3;
  }
  
  /* Mobile View Styles for Form */
  @media screen and (max-width: 768px) {
    
  
    .form-group {
        margin-bottom: 10px; /* Reduce margin between form groups */
    }
  
    .form-group label {
        font-size: 10px; /* Decrease font size of labels */
    }
  
    .form-input::placeholder {
        font-size: 12px; /* Decrease font size of input placeholders */
    }
  
  
   
  }
  
  
  /* Table Styles */
  .table-section {
    width: 40%;
  }
  
  .leave-table {
    width: 70%;
    border-collapse: collapse;
    margin-top: 130px;
  }
  
  .leave-table th,
  .leave-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
  }
  
  .leave-table th {
    background-color: #f2f2f2;
  }
  
  .leave-table tbody tr:hover {
    background-color: #f2f2f2;
  }
  
  .extra-row{
    font-weight: bold;
    background-color: #d7d3d3;
  }
  
  
  /* Existing styles... */
  
  /* Mobile View Styles for Form */
  @media screen and (max-width: 768px) {
    .main-section {
      width: calc(90% - 40px);
      flex-direction: column; /* Display main section in a single column */
    }
  
    .leave-section,
    .table-section {
      width: 100%; /* Take full width in mobile view */
    }
  
    .form-box {
        padding: 10px; /* Reduce padding for smaller screens */
        border: px solid #ccc;
        border-radius: 8px;
        margin-bottom: 0px;
    }
  
    .form-group {
      margin-bottom: 15px;
    }
  
    .form-group label {
      font-size: 15px; /* Keep font size of labels */
    }
    .leave-form {
      width: calc(90% - 40px);
        max-width: none; /* Remove max width for full width */
        height: auto; /* Allow form to grow in height */
    }
  
    .leave-form input[type="date"],
    .leave-form textarea,
    .leave-form select {
        padding: 8px; /* Reduce padding for inputs */
        font-size: 14px; /* Decrease font size of inputs */
    }
    .form-group select,
    .form-group textarea,
    .form-group input[type="date"] {
      padding: 10px;
      font-size: 16px;
    }
  
    .checkbox-item {
      margin-right: 10px; /* Reduce margin between checkbox items */
  }
  
  .checkbox-item label {
      font-size: 14px; /* Decrease font size of checkbox labels */
  }
  
  .checkboxes {
    display: flex;
    flex-wrap: wrap; /* Allow checkboxes to wrap on smaller screens */
  }
  
  .checkbox-item {
    flex: 1 1 50%; /* Each checkbox item takes 50% width */
    margin-bottom: 10px; /* Add margin bottom for spacing */
  }
  
  .checkbox-item label {
    font-size: 14px; /* Decrease font size of checkbox labels */
  }
    .Save-btn {
      margin-left: 0; /* Align button to the left */
      width: 100%; /* Make the button full width */
    }
  
    .left-paragraph {
      font-size: 13px;
      color: rgb(30, 176, 217);
      text-align: left;
    }
  
    .table-section {
      margin-top: 20px; /* Adjust margin between form and table */
    }
  
    .leave-table {
      width: 100%;
    }
  
    .leave-table th,
    .leave-table td {
      padding: 8px;
      border-bottom: 1px solid #ddd;
      text-align: left;
      font-size: 14px;
    }
  
    .leave-table th {
      background-color: #f2f2f2;
    }
  
    .leave-table tbody tr:hover {
      background-color: #f2f2f2;
    }
  
    .extra-row {
      font-weight: bold;
      background-color: #d7d3d3;
    }
    .Save-btn {
      margin-left: 10; /* Center the button */
      width: 40%; /* Make the button full width */
  }
  }
  