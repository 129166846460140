.leave-details-body {
    padding: 20px;
}

.leave-details-heading{
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 999; /* Ensures it stays above other content */
}


.leave-details-heading h1 {
    color: #575252;
    font-size: 20px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-container {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Adjust as needed */
    right: 0;
    width: 250px; 
    background-color: #ffffff;
    border: px solid #bdbdbd;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.user-info {
    border: px solid #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.user-info:hover{
    background-color: #f5f2f2;
}
.user-info-content {
    display: flex;
    align-items: center;
}

.profile-image-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    margin: 0;
    font-weight: bold;
}

.user-subtitle {
    margin: 0;
    color: #888888; /* Subtitle color */
}

.logout {
    display: flex;
    align-items: center;
}

.logout:hover{
    background-color: #edecec;
}
.logout svg {
    font-size: 15px;
    margin-right: 5px;
}

.logout p {
    margin: 0;
}

.separator {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #bdbdbd;
}


@media screen and (max-width: 768px){
    .leave-details-heading{
     width: calc(90% - 40px); 
    }
}


.enclosed-box {
    background-color: #f0f3fa;
    border: px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
}
.leave-details-section {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    gap: 30px;
    margin-top: 20px;
}

.leave-box {
    background-color: #ffffff;
    border: px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    width: 160px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leave-box p {
    font-weight: bold;
    color: #8e8989;
    margin: 0; /* Remove default margin */
}
.leave-box-count{
    font-size: 25px;
}



.button-container {
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
    margin-top: 20px;
}

.right-button {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #43db3b;
    border: 1.5px solid #43db3b ;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.right-button:hover {
    color: #ffffff;
    background-color: #43db3b;
}


.table-container {
    margin-top: 20px;
}

.table-detail {
    width: 100%;
    border-collapse: collapse;
}

.table-detail th, .table-detail td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.table-detail th {
    color: rgb(154, 152, 151);
    background-color: #ffffff;
    font-weight: bold;
}

.table-detail td {
    font-weight: bold;
    font-size: 12px;
    color: rgb(234, 119, 78);
}
.table-detail tbody {
    background-color: #fffdfd;
}


.table-detail tbody tr:hover {
    background-color: #f1f1f1;
}

.heading5-cell span {
    color: #ffffff;
    background-color: #43a924;
    display: inline-block;
    border: px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
}

.Heading8-cell span{
    color: blue;
}


@media screen and (max-width: 768px) {
   
    .enclosed-box {
        width: calc(90% - 40px);
        background-color: #f0f3fa;
        border: px solid #ccc;
        border-radius: 5px;
        padding: 20px;
        margin-top: 20px;
    }

    .leave-details-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 20px;
    }

    .leave-box {
        background-color: #ffffff;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 5px;
        width: calc(50% - 10px); /* Two boxes in a row */
        height: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .leave-box p {
        font-weight: bold;
        color: #8e8989;
        margin: 0;
    }

    .leave-box-count {
        font-size: 25px;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .right-button {
        padding: 10px 20px;
        background-color: #ffffff;
        color: #43db3b;
        border: 1.5px solid #43db3b;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    .right-button:hover {
        color: #ffffff;
        background-color: #43db3b;
    }

    .table-container {
        font-size: 15px;
        margin-top: 20px;
        overflow-x: auto;
    }

    .table-detail {
        width: 120%;
        border-collapse: collapse;
    }

    .table-detail th,
    .table-detail td {
        border-bottom: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .table-detail th {
        color: rgb(154, 152, 151);
        background-color: #ffffff;
        font-weight: bold;
    }

    .table-detail td {
        font-weight: bold;
        font-size: 12px;
        color: rgb(234, 119, 78);
    }

    .table-detail tbody {
        background-color: #fffdfd;
    }

    .table-detail tbody tr:hover {
        background-color: #f1f1f1;
    }

    .heading5-cell span {
        color: #ffffff;
        background-color: #43a924;
        display: inline-block;
        border: px solid #ccc;
        border-radius: 20px;
        padding: 5px 10px;
    }

    .Heading8-cell span {
        color: blue;
    }
}


    