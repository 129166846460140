.logo {
  margin-top: 50px;
  width: 130px;
  height: auto;
  margin-bottom: 30px;
}

.form-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(231, 229, 229);
}

.Login-form {
  width: 420px;
  height: 550px;
  padding: 50px;
  border: px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group {
  margin-bottom: 20px;
}

form label {
  color: #514f4f;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

form input {
  width: 100%;
  padding: 10px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
form a {
  color: #013bb7;
}
.Login-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  background-color: #45f270;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 30px;
}

.Login-button:hover {
  background-color: #169d38;
}

.google-login-btn {
  width: 320px;
  height: 50px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  color: rgb(32, 32, 183);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google-login {
  text-align: center;
}

h2 {
  color: #000000;
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
}

p {
  color: #6f6d6d;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 30px;
}
a {
  color: #3751f2;
  cursor: pointer;
}
.middle {
  color: #6f6d6d;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 30px;
}

form input::placeholder {
  font-size: 15px;
  color: #c4c0c0;
}

.forgot-password {
  font-size: 15px;
  color: #013bb7;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  form {
    width: 284px;
    height: 396px;
    top: 236px;
    left: 73px;
    gap: 0px;
    border-radius: 10px;
    border: 0.67px 0px 0px 0px;
    opacity: 0px;
    padding: 20px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    color: #6f6d6d;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .forgot-password {
    font-size: 10px;
  }

  .Login-btn {
    margin-bottom: 10px;
  }

  .google-login-btn {
    height: 34px;
    width: 100%;
    max-width: 284px;
    margin-bottom: 5px;
  }
  form input {
    height: 33.72px;
    font-size: 14px;
  }

  .google-login-btn,
  .send-button {
    font-size: 14px;
  }
}
