.logo {
  margin-top: 50px;
  width: 130px;
  height: auto;
  margin-bottom: 30px;
}

.form-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(231, 229, 229);
}

form {
  width: 420px;
  height: 480px;
  padding: 50px;
  border: px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group {
  margin-bottom: 20px;
}

form label {
  color: #514f4f;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

form input {
  width: 100%;
  padding: 10px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
form a {
  color: #013bb7;
}
.Login-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  background-color: #45f270;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 30px;
}

.Login-button:hover {
  background-color: #169d38;
}

.google-login-btn {
  width: 320px;
  height: 50px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  color: rgb(32, 32, 183);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google-login {
  text-align: center;
}

h2 {
  color: #000000;
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
}

p {
  color: #6f6d6d;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 30px;
}
a {
  color: #3751f2;
}
.middle {
  color: #6f6d6d;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 30px;
}

form input::placeholder {
  font-size: 15px;
  color: #c4c0c0;
}

.forgot-password {
  font-size: 15px;
  color: #013bb7;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  form {
    width: 284px;
    height: 396px;
    top: 236px;
    left: 73px;
    gap: 0px;
    border-radius: 17.53px 0px 0px 0px;
    border: 0.67px 0px 0px 0px;
    opacity: 0px;
    padding: 20px; /* Adjust padding as needed */
  }

  h2 {
    font-size: 20px;
  }

  p {
    color: #6f6d6d;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .forgot-password {
    font-size: 10px;
  }

  .Login-btn {
    margin-bottom: 10px; /* Reduce margin for better spacing */
  }

  .google-login-btn {
    height: 34px;
    width: 100%; /* Adjust width for responsiveness */
    max-width: 284px; /* Limit width for smaller screens */
    margin-bottom: 5px; /* Adjust margin as needed */
  }
  form input {
    height: 33.72px; /* Adjust height of form input */
    font-size: 14px; /* Adjust font size for better readability */
  }

  .google-login-btn,
  .send-button {
    font-size: 14px; /* Adjust font size for better readability */
  }
}

/* Forget Password */
.Password-logo {
  width: 300px; /* Adjust the width as needed */
  height: auto;
  margin-bottom: 50px;
}
.Forgrt-Password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}

.forget-box {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  width: 420px;
  height: 363px;
  gap: 0px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border: px solid #ccc;
}

.forget-box h2 {
  font-size: 30px;
}

.forget-box p {
  color: #49556f;
  font-size: 14px;
}

.reset-option {
  width: 380px;
  height: 86px;
  border-radius: 10px;
  border: 1px 0px 0px 0px;
  border: 2px solid #1ab315;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.email-icon {
  margin-left: 10px;
  background-color: #eff4ff;
  border: px solid gray;
  width: 70px; /* Increase the width */
  height: 50px; /* Increase the height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-right: 10px;
  color: #32dd2c; /* Adjust the color as needed */
}
.reset-description {
  font-size: 14px;
  color: #666;
}

.reset-description span {
  font-family: "DM Sans", sans-serif;
  color: #32dd2c;
  font-weight: bold;
  margin-bottom: 15px; /* Adjust spacing between elements */
}

.reset-description p {
  font-family: "DM Sans", sans-serif;
  color: #49556f;
  font-size: 11px;
  text-align: left;
  margin: 0;
}

.send-button {
  width: 380px;
  height: 50px;
  border-radius: 10px;
  background-color: #32dd2c;
  color: white;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .forget-box {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    width: 284px;
    height: 396px;
  }

  .reset-option {
    width: 290px;
    height: 70px;
  }

  .send-button {
    width: 290px;
    height: 40px;
  }
}

/* New password Css */

.New-box {
  width: 420px;
  height: 450px;
  gap: 0px;
  border-radius: 26px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  border: px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the inputs horizontally */
  width: 100%;
  max-width: 300px; /* Adjust as needed */
}

.password-input {
  width: 340px;
  height: 50px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.password-input:focus {
  outline: none;
  border-color: #007bff;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ccc;
}

.password-toggle:hover {
  color: #333;
}

.New-box h2 {
  color: #000000;
  text-align: center;
  font-size: 30px;
}

.New-box p {
  color: #6f6d6d;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 30px;
}

.reset-button {
  width: 340px;
  height: 50px;
  background-color: #3751f2;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}
a {
  color: #49556f;
}
.reset-button:hover {
  background-color: #7382e3;
}

.password-strength-bar {
  height: 8px;
  border-radius: 4px;
  transition: width 0.3s ease;
}

@media only screen and (max-width: 600px) {
  .New-box {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    width: 284px;
    height: 396px;
  }

  .password-input {
    width: 229.29px;
    height: 33.72px;
  }

  .reset-button {
    width: 229.29px;
    height: 33.72px;
  }
}

/* Sucessfull style */
.Success-box {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  width: 420px;
  height: 397px;
  border-radius: 26px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border: px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Success-logo {
  width: 155.24px;
  height: 136px;
  margin-bottom: 20px; /* Adjust as needed */
}

.arrow-icon {
  margin-left: 10px; /* Adjust the space as needed */
}
.go-to-login a {
  color: #3751f2;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .Success-box {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    width: 284px;
    height: 396px;
  }
}

/* singupu css */
.signupform {
  width: 420px;
  height: auto;
  padding: 50px;
  border: px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
