body{
  background-color: rgb(231, 229, 229);
}
.Forgrt-Password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(231, 229, 229);
  }
  .Forgrt-Password img{
    max-width: 150px;
    margin-bottom: 10px;
    }
  .page-box {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    width: 716px;
    height: 364px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border: px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-box {
    display: flex;
  }
  
  .profile-1,
  .profile-2 {
    width: 274px;
    height: 175px;
    border-radius: 26px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    border: 0px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    margin: 0 10px;
  }

  .profile-1 p{
    font-weight: 500;
    color: rgb(87, 218, 85);
    font-size: 20px;
    margin-top: 5px;
  }
  .profile-2 p {
    font-weight: 500;
    color: rgb(87, 218, 85);
    font-size: 20px;
    margin-top: 5px;
  }
  
  .page-icon{
    width: 70px;
    height: 52px;
    color: rgb(87, 218, 85);
  }




/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .Forgrt-Password {
    height: auto;
    padding: 20px;
  }

  .page-box {
    height: auto;
    max-width: 90%;
    max-height: none;
  }

  .profile-box {
    flex-direction: column;
    margin-top: 20px;
  }

  .profile-1,
  .profile-2 {
    box-shadow:none;
    border: none;
    width: 100%;
    margin: 10px 0;
  }

  .page-icon {
    width: 50px;
    height: 40px;
  }

  .Forgrt-Password img {
    max-width: 100px;
    margin-bottom: 10px;
  }
}
